import React from 'react';
import ContactCard from '../components/contactcard';
import DownloadContactCard from '../components/downloadcontactcard';


function ContactPage() {
    return (
        <div class="page">
            <ContactCard />
            <DownloadContactCard />
        </div>
    );
};

export default ContactPage;