import React from 'react';

const Privacy = () => {
    return (
        <div>
            <h1>Privacy Statement</h1>
            <p>This is the privacy statement for our website.</p>
            {/* Add your GDPR compliant privacy statement content here */}
        </div>
    );
};

export default Privacy;