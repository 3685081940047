import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QRCodeSVG from 'qrcode.react';

import './contactcard.css';

const ContactCard = () => {
    const [contact, setContact] = useState({});
    const { id } = useParams();

    useEffect(() => {
        const getPost = async () => {
            const resp = await fetch(`/api/contacts/${id}`);
            const contactResponse = await resp.json();
            setContact(contactResponse);
        };

        getPost();
    }, [id]);


    if (!Object.keys(contact).length) { 
        return null;
    }

    return (
        <div className="contact-card">
            <div class="card">
                <div class="card-content-wrapper">
                    <div class="header-left">
                        <img src={contact.contactImage.url} alt={contact.contactImage.tag} />
                    </div>
                    <div class="header-right">
                        <h1>{contact.contactName}</h1>
                        <h2>{contact.contactTitle}</h2>
                    </div>
                </div>
                <div class="card-content-wrapper">
                    <div class="detail-left">
                        <p><i class="fa fa-phone fa-fw" aria-hidden="true"></i>&nbsp;{contact.contactPhone}</p>
                        <p><i class="fa fa-envelope-o fa-fw" aria-hidden="true"></i>&nbsp;<Link to={contact.contactEmail.url}>{contact.contactEmail.tag}</Link></p>
                        <p><i class="fa fa-map-marker fa-fw" aria-hidden="true"></i>&nbsp;{contact.contactAddress}</p>
                        <p><i class="fa fa-linkedin-square fa-fw" aria-hidden="true"></i>&nbsp;<Link to={contact.contactLinkedIn.url}>{contact.contactLinkedIn.tag}</Link></p>
                    </div>
                    <div class="detail-right">
                        <QRCodeSVG value={window.location.href} size={128} level={"Q"} includeMargin={true}  
                            imageSettings={{
                            src: "/favicon.png",
                            x: undefined,
                            y: undefined,
                            height: 24,
                            width: 24,
                            excavate: true,
                            }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactCard;