import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {

    return (
        <div class="header row align-items-space-between">
            <div class="col"><Link to='/'>iamcontactable.com</Link></div>
            <div class="col"></div>
            <div class="col"></div>
        </div>
    );
};

export default Header;