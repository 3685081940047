import React from 'react';
import Privacy from '../components/privacy';

function PrivacyPage() {
    return (
        <div class="page">
            <Privacy />
        </div>
    );
};

export default PrivacyPage;