import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './downloadcontactcard.css';

const DownloadContactCard = () => {
    const [contact, setContact] = useState({});
    const { id } = useParams();

    /* TODO: Inefficient - we will end up calling the function twice. */
    useEffect(() => {
        const getPost = async () => {
            const resp = await fetch(`/api/contacts/${id}`);
            const contactResponse = await resp.json();
            setContact(contactResponse);
        };

        getPost();
    }, [id]);

    if (!Object.keys(contact).length) { 
        return null;
    }

    const vcardContent = `BEGIN:VCARD
VERSION:3.0
N:;Whitford;Anthony;;;
FN:${contact.contactName}
TITLE:${contact.contactTitle}
EMAIL:${contact.contactEmail.tag}
TEL:${contact.contactPhone}
ADR:${contact.contactAddress}
KIND:individual
URL:${contact.contactLinkedIn.url}
SOURCE:https://iamcontactable.com
NOTE:Powered by hello@iamcontactable.com
END:VCARD
`;


    const handleDownload = () => {
      const url = window.URL.createObjectURL(new Blob([vcardContent]));
      const link = document.createElement("a");
      link.href = url;
      link.download = [contact.contactName].join("_") + ".vcf";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  };

  return (
    <div class="download-contact-card mt-5">
      <button type="button" onClick={handleDownload}>
        Add to Contacts
      </button>
    </div>
  );
};

export default DownloadContactCard;