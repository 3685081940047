import './App.css'

import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'; 

import Layout from './components/Layout';

import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PrivacyPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />

          <Route path="/me/:id" element={<ContactPage />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;