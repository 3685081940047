import React from 'react';

function HomePage() {
    return (
        <div>
            <p>If you want to be contactable, please reach out to <a href="mailto:hello@iamcontactable.com">hello@iamcontactable.com</a>!</p>
        </div>
    );
};

export default HomePage;